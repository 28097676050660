
























import {Component, Vue, Prop} from 'vue-property-decorator';
import PageTitle from '@/components/page-title.vue';
import {generateSlug} from '@/utils';
import {empty} from '@/utils';

@Component({
  components: {PageTitle},
  metaInfo() {
    return {
      // @ts-ignore
      title: this.blog.metaTitle || 'Blog Title - Ocere',
      meta: [
        {
          name: 'description',
          // @ts-ignore
          content: this.blog.metaDescription || 'Blog Detail - Ocere',
        },
      ],
    };
  },
})
export default class extends Vue {
  @Prop() blog;
}
